export const statuses = [ "UNDEFINED", "DENY", "ALLOW", "SAVE"];
export const statusUndefined = "UNDEFINED";
export const statusDeny = "DENY";
export const statusAllow = "ALLOW";
export const statusSave = "SAVE";

export const positionBannerTop = "banner-top";
export const positionBannerBottom = "banner-bottom";
export const positionOverlay = "overlay";
export const positionBanner = "banner";

export const hash = str => {
    let hashNum = 0,
        i = 0,
        chr,
        len = str.length;
    if (str.length === 0) return hashNum;
    for ( i; i < len; ++i ) {
        chr = str.charCodeAt( i );
        hashNum = ( hashNum << 5 ) - hashNum + chr;
        hashNum |= 0
    }
    return hashNum
};
