import {addCustomStylesheet, traverseDOMPath} from "../util/dom";
import {positionBanner, positionBannerBottom, statuses} from "../util/constants";
import './../styles/CookieView.min.css';

export default class CookieView {

    constructor(config) {
        this.config = config;
        this.originalBodyPadding = '0px';
        this.originalBodyPaddingInit = false;
        this.hasTransition = !!(function () {
            const el = document.createElement('div');
            const trans = {
                t: 'transitionend',
                OT: 'oTransitionEnd',
                msT: 'MSTransitionEnd',
                MozT: 'transitionend',
                WebkitT: 'webkitTransitionEnd'
            };

            for (let prefix in trans) {
                if (
                    trans.hasOwnProperty(prefix) &&
                    typeof el.style[prefix + 'ransition'] !== 'undefined'
                ) {
                    return trans[prefix]
                }
            }
            return ''
        })();
    }

    updateConfig(config) {
        this.config = config;
    }

    showDialog(buttonClickCallback) {
        if (!this.element) {
            addCustomStylesheet(this.config.gColPalHash(), '.' + this.config.gColPalId(), this.config);
            this.element = this._aM(this.config.getWindow(), buttonClickCallback);
        }
        this._open();
    }

    hideDialog() {
        if (!this.element) return;
        if (this.isOpen()) {
            this._close();
        }
    }

    isOpen() {
        return (
            this.element &&
            this.element.style.display === '' &&
            (this.hasTransition ? !this.element.classList.contains('iw-cc-invisible') : true)
        )
    }

    _open() {
        if (!this.element) return;

        if (!this.isOpen()) {
            if (this.hasTransition) {
                this._fadeIn();
            } else {
                this.element.style.display = '';
                this._aBP();
            }
        }

        return this
    }

    _close() {
        if (!this.element) return;

        if (this.isOpen()) {
            if (this.hasTransition) {
                this._fadeOut();
            } else {
                this.element.style.display = 'none';
            }
        }

        this._rBP();

        return this
    }

    _gCFP(){
        if(this.config.gMBPC()){
            return this.config.gMBPC();
        }
        return document.body;
    }

    _aBP(){
        const addPaddingToBottom = this.config.gPos() === positionBannerBottom && !this.config.gMBPI();
        if(this.config.gMBP() && this.config.gPosStyl() === positionBanner){
            if(!this.originalBodyPaddingInit){
                if(addPaddingToBottom){
                    this.originalBodyPadding = this._gCFP().style.paddingBottom;
                }else{
                    this.originalBodyPadding = this._gCFP().style.paddingTop;
                }
            }
            const banner = document.getElementsByClassName("iw-cc-banner");
            if(banner[0]){
                const height = banner[0].offsetHeight;
                const body_padding_bottom = String(this.originalBodyPadding).replace('px', '');
                const body_padding_bottom_parsed = parseInt(body_padding_bottom);
                if(isNaN(body_padding_bottom_parsed)){
                    if(addPaddingToBottom){
                        this._gCFP().style.paddingBottom = height + 'px';
                    }else{
                        this._gCFP().style.paddingTop = height + 'px';
                    }
                }else{
                    const new_padding_bottom = body_padding_bottom_parsed + height;
                    if(addPaddingToBottom){
                        this._gCFP().style.paddingBottom = new_padding_bottom + 'px';
                    }else{
                        this._gCFP().style.paddingTop = new_padding_bottom + 'px';
                    }
                }
            }
        }
    }

    _rBP(){
        const addPaddingToBottom = this.config.gPos() === positionBannerBottom && !this.config.gMBPI();
        if(this.config.gMBP() && this.config.gPosStyl() === positionBanner){
            if(addPaddingToBottom){
                this._gCFP().style.paddingBottom = this.originalBodyPadding;
            }else{
                this._gCFP().style.paddingTop = this.originalBodyPadding;
            }
        }
    }

    _fadeIn() {
        const el = this.element;

        if (!this.hasTransition || !el) return;

        // This should always be called AFTER fadeOut (which is governed by the 'transitionend' event).
        // 'transitionend' isn't all that reliable, so, if we try and fadeIn before 'transitionend' has
        // has a chance to run, then we run it ourselves
        if (this.closingTimeout) {
            this._afterFadeOut(el)
        }

        if (el.classList.contains('iw-cc-invisible')) {
            el.style.display = '';

            const fadeInTimeout = 20; // (ms) DO NOT MAKE THIS VALUE SMALLER. See below

            // Although most browsers can handle values less than 20ms, it should remain above this value.
            // This is because we are waiting for a "browser redraw" before we remove the 'iw-cc-invisible' class.
            // If the class is remvoed before a redraw could happen, then the fadeIn effect WILL NOT work, and
            // the popup will appear from nothing. Therefore we MUST allow enough time for the browser to do
            // its thing. The actually difference between using 0 and 20 in a set timeout is neglegible anyway
            this.openingTimeout = setTimeout(
                () => this._afterFadeIn(el),
                fadeInTimeout
            )
        }
    }

    /**
     * This needs to be called after 'fadeIn'. This is the code that actually causes the fadeIn to work
     * There is a good reason why it's called in a timeout. Read 'fadeIn'
     */
    _afterFadeIn(element) {
        this.openingTimeout = null;
        element.classList.remove('iw-cc-invisible');
        this._aBP();
    }

    _fadeOut() {
        if (!this.hasTransition || !this.element) return;

        if (this.openingTimeout) {
            clearTimeout(this.openingTimeout);
            this._afterFadeIn(this.element)
        }

        if (!this.element.classList.contains('iw-cc-invisible')) {

            this.element.classList.add('iw-cc-invisible');

            const fadeOutTimeout = 500;

            this.closingTimeout = setTimeout(
                () => this._afterFadeOut(this.element),
                fadeOutTimeout
            )
        }
    }

    _afterFadeOut(el) {
        el.style.display = 'none'; // after close and before open, the display should be none
        this.closingTimeout = null
    }


    _aM(markup, buttonClickCallback) {
        const div = document.createElement('div');
        const cont =
            this.config.getContainer() && this.config.getContainer().nodeType === 1
                ? this.config.getContainer()
                : document.body;

        div.innerHTML = markup;

        const el = div.children[0];

        el.style.display = 'none';

        if (el.classList.contains('iw-cc-w') && this.hasTransition) {
            el.classList.add('iw-cc-invisible')
        }

        el.addEventListener('click', event => this._hBC(event, buttonClickCallback));

        try {
            if (!cont.firstChild) {
                cont.appendChild(el)
            } else {
                cont.insertBefore(el, cont.firstChild);
            }
        } catch (error) {
            console.log('CV: error: ' + error);
        }
        return el;
    }

    _hBC(event, buttonClickCallback) {
        // returns the parent element with the specified class, or the original element - null if not found
        const btn = traverseDOMPath(event.target, 'iw-cc-btn-') || event.target;

        if (btn.classList.contains('iw-cc-btn')) {
            const matches = btn.className.match(
                new RegExp('\\biw-cc-(' + statuses.map(str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')).join('|') + ')\\b')
            );
            const match = (matches && matches[1]) || false;
            if (match) {
                // get accepted checkboxes
                const types = this.config.gP(['types']);
                let typeIds = [];
                for (let type of types) {
                    const checkBoxId = 'iw-cc-checkbox-' + type['id'];
                    const htmlElement = document.getElementById(checkBoxId);
                    if (htmlElement && htmlElement.checked) {
                        typeIds.push(type['id']);
                    }
                }
                buttonClickCallback(match, typeIds);
                this.hideDialog();
            }
        }
    }
}
