import CookieLogic from "./util/CookieLogic";

const CookieSingleton = (function () {
    let instance;
    return {
        getInstance: function (options) {
            if (!instance) {
                instance = new CookieLogic(options);
            } else {
                if (options) {
                    instance.updateConfig(options);
                }
            }
            return instance;
        }
    };
})();

export function initialize(options) {
    return CookieSingleton.getInstance(options);
}

export function reset() {
    CookieSingleton.getInstance().reset();
    location.reload();
}

export function cookiesAllowed() {
    return CookieSingleton.getInstance().cookiesAllowed();
}

export function typeAllowed(type) {
    return CookieSingleton.getInstance().typeAllowed(type);
}

/**
 * Called from GTM template to set callback to be executed when user consent is provided.
 * @param name
 * @param callback
 */
window.addIwConsentListener = (name, callback) => {
    if(CookieSingleton.getInstance().consentListenerNames.includes(name)){
        return;
    }
    CookieSingleton.getInstance().consentListenerNames.push(name);
    CookieSingleton.getInstance().consentListeners.push(callback);
};
