import CookieConfig from "../../config/CookieConfig";

const _normalizeHex = hex =>
    hex[0] === '#'
        ? hex.substr(1) : hex.length === 3
        ? hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] : hex;

// used to get text colors if not set
const _gContrast = hex => {
    hex = _normalizeHex(hex);
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? '#000' : '#fff'
};

export const traverseDOMPath = (elem, className) =>
    !elem || !elem.parentNode
        ? null : elem.classList.contains(className)
        ? elem : traverseDOMPath(elem.parentNode, className);


export const addCustomStylesheet = (id, prefix, config) => {
    const colorStyles = {};

    if (config instanceof CookieConfig) {
        const popupBackground = config.gP(['palette', 'popup', 'background']);
        let popupText = config.gP(['palette', 'popup', 'text']);
        const popupLink = config.gP(['palette', 'popup', 'link']);

        popupText = popupText ? popupText : _gContrast(popupBackground);

        colorStyles[prefix + '.iw-cc-w .iw-cc-wrapper'] = [
            'color: ' + popupText,
            'background-color: ' + popupBackground,
        ];
        colorStyles[prefix + ' .iw-cc-link,' + prefix + ' .iw-cc-link:active,' + prefix + ' .iw-cc-link:visited'] = [
            'color: ' + (popupLink ? popupLink : popupText),
        ];
        colorStyles[prefix + ' .iw-cc-checkbox label'] = [
            'color: ' + popupText
        ];

        let btnABg = config.gP(['palette', 'buttonAllow', 'background']);
        let btnABgHov = config.gP(['palette', 'buttonAllow', 'backgroundHover']);
        const bAP = config.gP(['palette', 'buttonAllow', 'padding']);
        const btnATxt = config.gP(['palette', 'buttonAllow', 'text']);
        const btnATxtHov = config.gP(['palette', 'buttonAllow', 'textHover']);
        const btnABord = config.gP(['palette', 'buttonAllow', 'border']);
        const btnABordHov = config.gP(['palette', 'buttonAllow', 'borderHover']);

        btnABg = btnABg ? btnABg : _gContrast(popupBackground);
        btnABgHov = btnABgHov ? btnABgHov : btnABg;

        colorStyles[prefix + ' .iw-cc-btn-ALLOW'] = [
            'color: ' + (btnATxt ? btnATxt : _gContrast(btnABg)),
            'border-color: ' + (btnABord ? btnABord : 'transparent'),
            'background-color: ' + btnABg,
        ];

        colorStyles[prefix + ' .iw-cc-btn-ALLOW:hover, ' + prefix + ' .iw-cc-btn-ALLOW:focus'] = [
            'color: ' + (btnATxtHov ? btnATxtHov : _gContrast(btnABgHov)),
            'border-color: ' + (btnABordHov ? btnABordHov : 'transparent'),
            'background-color: ' + btnABgHov,
        ];

        if (bAP) {
            colorStyles[prefix + ' .iw-cc-btn-ALLOW'].push('padding: ' + bAP);
        }

        let btnDBg = config.gP(['palette', 'buttonDeny', 'background']);
        let btnDBgHov = config.gP(['palette', 'buttonDeny', 'backgroundHover']);
        const btnDP = config.gP(['palette', 'buttonDeny', 'padding']);
        const btnDTxt = config.gP(['palette', 'buttonDeny', 'text']);
        const btnDTxtHov = config.gP(['palette', 'buttonDeny', 'textHover']);
        const btnDBord = config.gP(['palette', 'buttonDeny', 'border']);
        const btnDBordHov = config.gP(['palette', 'buttonDeny', 'borderHover']);

        btnDBg = btnDBg ? btnDBg : _gContrast(popupBackground);
        btnDBgHov = btnDBgHov ? btnDBgHov : btnDBg;
        const denyOrSave = [
            'color: ' + (btnDTxt ? btnDTxt : _gContrast(btnDBg)),
            'border-color: ' + (btnDBord ? btnDBord : 'transparent'),
            'background-color: ' + btnDBg,
        ];
        colorStyles[prefix + ' .iw-cc-btn-DENY'] = denyOrSave;
        colorStyles[prefix + ' .iw-cc-btn-SAVE'] = denyOrSave;

        const denyOrSaveHover = [
            'color: ' + (btnDTxtHov ? btnDTxtHov : _gContrast(btnDBgHov)),
            'border-color: ' + (btnDBordHov ? btnDBordHov : 'transparent'),
            'background-color: ' + btnDBgHov,
        ];
        colorStyles[prefix + ' .iw-cc-btn-DENY:hover, ' + prefix + ' .iw-cc-btn-DENY:focus'] = denyOrSaveHover;
        colorStyles[prefix + ' .iw-cc-btn-SAVE:hover, ' + prefix + ' .iw-cc-btn-SAVE:focus'] = denyOrSaveHover;

        if (btnDP) {
            colorStyles[prefix + ' .iw-cc-btn-DENY'].push('padding: ' + btnDP);
            colorStyles[prefix + ' .iw-cc-btn-SAVE'].push('padding: ' + btnDP);
        }

        const overlayBackground = config.gP(['palette', 'overlay', 'background']);
        colorStyles[prefix + ' .iw-cc-overlay-bg'] = [
            'background-color: ' + (overlayBackground ? overlayBackground : 'transparent'),
        ];

        // checkboxes
        const checkBoxBorderColor = btnABord ? btnABord : '#000';
        colorStyles[prefix + ' .iw-cc-checkboxes input[type="checkbox"] + ::before'] = [
            'border-color: ' + checkBoxBorderColor,
        ];
        colorStyles[prefix + ' .iw-cc-checkboxes input[type="checkbox"]:checked + ::before'] = [
            'background-color: ' + checkBoxBorderColor,
        ];
    }

    // this will be interpretted as CSS. the key is the selector, and each array element is a rule
    const style = document.createElement('style');
    style.id = id;
    document.head.appendChild(style);
    Object.entries(colorStyles).forEach(([prop, value], index) =>
        style.sheet.insertRule(`${prop}{${value.join(';')}}`, index)
    );
    return style;
};
