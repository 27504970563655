export const gC = name => {
    const value = ' ' + document.cookie;
    const parts = value.split(' ' + name + '=');
    return parts.length < 2
        ? undefined
        : parts
            .pop()
            .split(';')
            .shift();
};

export const sC = function (name, value, expiryDays, domain, path, secure) {
    const exdate = new Date();
    exdate.setHours(exdate.getHours() + ((typeof expiryDays !== "number" ? 365 : expiryDays) * 24));
    document.cookie = name + '=' + value +
        ';expires=' + exdate.toUTCString() +
        ';path=' + (path || '/') +
        (domain ? ';domain=' + domain : '') +
        (secure ? ';secure' : '');
};

const _getNames = name => {
    const value = ' ' + document.cookie;
    const parts = value.split(' ');
    const results = [];
    for (const part of parts) {
        if(part.startsWith(name)){
            const cookieParts = part.split('=');
            if(cookieParts.length >= 2){
                results.push(cookieParts[0]);
            }
        }
    }
    return results;
};

export const dCs = function (name, expiryDays, domain, path, secure) {
    const cookieFullNames = _getNames(name);
    for (const cookieName of cookieFullNames) {
        dC(cookieName, expiryDays, domain, path, secure);
    }
};

export const dC = function (name, expiryDays, domain, path, secure) {
    if(gC(name)){
        const exdate = new Date();
        exdate.setHours(exdate.getHours() + ((typeof expiryDays !== "number" ? 365 : expiryDays) * -24));
        document.cookie = name + '=' +
            ';expires=' + exdate.toUTCString() +
            ';path=' + (path || '/') +
            (domain ? ';domain=' + domain : '') +
            (secure ? ';secure' : '');
    }
};
