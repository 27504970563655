import {dC, dCs} from "../util/cookie";

export default class Tracking {

    constructor(config) {
        this.config = config;
        this.insertedMatomo = false;
        this.insertedGTM = false;
        this.insertedGA = false;
    }

    insertTracking(allowedTypeIds){
        if(this.config.gP(['tracking', 'google_analytics_id'])){
            if(allowedTypeIds !== false && allowedTypeIds.includes('statistic')) {
                this._iGA();
            }
        }
        if(this.config.gP(['tracking', 'google_tag_manager_id'])){
            this._iGTM(allowedTypeIds);
        }
        if(this.config.gP(['tracking', 'matomo_url']) && this.config.gP(['tracking', 'matomo_id'])){
            if(allowedTypeIds !== false && allowedTypeIds.includes('statistic')){
                this._iM(false, allowedTypeIds);
            }else{
                this._iM(true, allowedTypeIds);
            }
        }
    }

    removeTrackingCookies(){
        this._rGAC();
        this._rMC();
    }

    _rGAC(){
        dC('_ga');
        dC('_gid');

        //delete for multidomain
        let domainName = this._gDN();
        if(domainName){
            domainName = '.' + domainName;
        }
        dC('_ga', 1, domainName);
        dC('_gid', 1, domainName);
    }

    _rMC(){
        dCs('_pk_');

        //delete for multidomain
        let domainName = this._gDN();
        if(domainName){
            domainName = '.' + domainName;
        }
        dCs('_pk_', 1, domainName);
    }

    _gDN() {
        if(window.location && window.location.hostname){
            const hostname = window.location.hostname;
            return hostname.substring(hostname.lastIndexOf(".", hostname.lastIndexOf(".") - 1) + 1);
        }
        return undefined;
    }

    _iGA(){
        if(this.insertedGA){
            return;
        }
        this.insertedGA = true;
        const code = `
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
ga('create', '` + this.config.gP(['tracking', 'google_analytics_id']) + `', 'auto');
ga('set', 'anonymizeIp', true);
ga('send', 'pageview');
`;
        Tracking._iIH(code);
    }

    _iGTM(allowedTypeIds){
        window.dataLayer = window.dataLayer || [];
        const tagManagerData = {
            'event': 'iwCookieTrigger',
            'iw_cookie_allowed': allowedTypeIds,
        };
        window.dataLayer.push(tagManagerData);
        if(this.insertedGTM){
            return;
        }
        this.insertedGTM = true;
        const code = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','` + this.config.gP(['tracking', 'google_tag_manager_id']) + `');
`;
        Tracking._iIH(code);
    }

    _iM(anonymous, allowedTypeIds){
        if(this.insertedMatomo){
            return;
        }
        this.insertedMatomo = true;

        let code = `var _paq = _paq || [];`;
        if(anonymous){
            code += `
_paq.push(['disableCookies']);
_paq.push(['setCustomVariable', 1, 'disableCookies', 'true', 'pageview']);
_paq.push(['setCustomDimension', 1, 'true']);
`;
        }else{
            code += `
_paq.push(['setCustomVariable', 1, 'disableCookies', 'false', 'pageview']);
_paq.push(['setCustomDimension', 1, 'false']);
`;
        }

        if(allowedTypeIds !== false){
            code += `
_paq.push(['setCustomDimension', 2, '` + allowedTypeIds.toString() + `']);
`;
        }

        code += `
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
  var u="` + this.config.gP(['tracking', 'matomo_url']) + `/";
  _paq.push(['setTrackerUrl', u+'matomo.php']);
  _paq.push(['setSiteId', '` + this.config.gP(['tracking', 'matomo_id']) + `']);
  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
})();
`;
        Tracking._iIH(code);
    }

    static _iIH(code){
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = code;
        document.getElementsByTagName('head')[0].appendChild(script);
    }
}
