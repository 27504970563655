import {statusAllow, statusDeny, statusSave} from "../../util/constants";

export default {
    enabled: true,
    disable_for_bots: true,
    container: null,
    tracking: {
        enable_anonymous_tracking: true,
        google_analytics_id: '',
        google_tag_manager_id: '',
        matomo_url: '',
        matomo_id: '',
    },
    cookie: {
        name: 'iw_optional_cookies_status',
        nameSaved: 'iw_allowed_cookie_ids',
        path: '/',
        domain: false,
        expiryDays: 365,
        secure: false
    },
    types: [
        {
            name: 'Essenziell',
            id: 'always',
            force: true,
        },
        {
            name: 'Statistik',
            id: 'statistic',
        },
        {
            name: 'Marketing',
            id: 'marketing',
        },
        {
            name: 'Externe Medien',
            id: 'external',
        },
    ],
    content: {
        header: 'Datenschutzeinstellungen',
        message: 'Wir nutzen auf unserer Website verschiedene Cookies: Einige sind essenziell für die Funktionalität, andere helfen uns dabei, die Nutzenden besser zu verstehen. Sie sind also eine Hilfe, unsere Leistungen stetig zu optimieren und das Resultat ist ein deutlich besseres Erlebnis bei der Nutzung dieser Website. Einige Cookies, sofern zugestimmt, nutzen anonymisierte, personenbezogene Daten – unsere Website funktioniert aber auch ohne.',
        question: 'Welche Cookies dürfen verwendet werden?',
        allow: 'ALLE COOKIES',
        deny: 'NUR NOTWENDIGE',
        save: 'SPEICHERN',
        links: [
            {title: '', url: ''},
        ],
        target: '_blank',
        image: {title: '', url: ''},
    },
    // position like banner-bottom or banner-top for banner and overlay for overlay
    position: 'banner-bottom',
    // fallback_position can be banner-bottom or banner-top
    fallback_position: 'banner-bottom',
    // if position overlay switch to banner for certain small devices
    disable_overlay_for_low_height: true,
    // default: move body by adding padding top or bottom
    modify_body_padding: true,
    // select other container for moving then document.body
    modify_body_padding_container: false,
    // inverse bottom and top padding
    modify_body_padding_inverse: false,
    // colors
    palette: {
        popup: {background: '#FFF', text: '#1D1D1D', link: '#CCC'},
        buttonAllow: {background: '#FFF', border: '#50F550', text: '#1D1D1D', backgroundHover: '#50F550', borderHover: '#50F550', textHover: '#1D1D1D' },
        buttonDeny: {background: '#FFF', border: '#CCC', text: '#1D1D1D' , backgroundHover: '#CCC', borderHover: '#CCC', textHover: '#1D1D1D'},
        overlay: {background: 'rgba(255,255,255,0.8)'},
    },
    elements: {
        header:
            '<span class="iw-cc-header">{{header}}</span>',
        message:
            '<span class="iw-cc-message">{{message}}</span>',
        question:
            '<span class="iw-cc-question">{{question}}</span>',
        allow:
            `<div class="iw-cc-btn-wrapper {{pos}}"><a aria-label="{{allow}}" role=button tabindex="0"  class="iw-cc-btn iw-cc-btn-${statusAllow} iw-cc-${statusAllow}" rel="noopener noreferrer nofollow">{{allow}}</a></div>`,
        deny:
            `<div class="iw-cc-btn-wrapper {{pos}}"><a aria-label="{{deny}}" role=button tabindex="0" class="iw-cc-btn iw-cc-btn-${statusDeny} iw-cc-${statusDeny}" rel="noopener noreferrer nofollow">{{deny}}</a></div>`,
        save:
            `<div class="iw-cc-btn-wrapper {{pos}}"><a aria-label="{{save}}" role=button tabindex="0" class="iw-cc-btn iw-cc-btn-${statusSave} iw-cc-${statusSave}" rel="noopener noreferrer nofollow">{{save}}</a></div>`,
        link:
            '<a aria-label="{{title}}" role=button tabindex="0" class="iw-cc-link" href="{{url}}" rel="noopener noreferrer nofollow" target="{{target}}">{{title}}</a>',
        image:
            '<div class="iw-cc-img-wrapper"><img aria-label="{{title}}" alt="{{title}}" class="iw-cc-img" src="{{url}}"/></div>',
        buttons:
            '<div class="iw-cc-buttons">{{allow}}{{deny}}<div class="iw-cc-clearfix"></div></div>',
        checkboxes:
            '<div class="iw-cc-checkboxes">{{checkboxes}}<div class="iw-cc-clearfix"></div></div>',
        checkbox:
            '<div class="iw-cc-checkbox {{force}}"><input type="checkbox" id="{{id}}" name="{{id}}" {{checked}}><label for="{{id}}">{{name}}</label></div>',
        links:
            '<div class="iw-cc-links">{{links}}<div class="iw-cc-clearfix"></div></div>',
        window:
            '<div role="dialog" aria-live="polite" aria-label="Cookie Banner" class="iw-cc-w {{classes}}"><!--googleoff: all-->{{children}}<!--googleon: all--></div>',
        overlay:
            '<div class="iw-cc-overlay-bg"></div>',
        wrapper:
            '<div class="iw-cc-wrapper">{{children}}</div>',
    },
}
