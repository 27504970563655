import CookieConfig from "../config/CookieConfig";
import CookieView from "../view/CookieView";
import {gC, sC, dC} from "./cookie";
import {positionOverlay, statusAllow, statusDeny, statuses, statusSave, statusUndefined} from "./constants";
import EventEmitter from "events"
import Tracking from "../tracking/Tracking";

const emitter = new EventEmitter();

export default class CookieLogic {

    constructor(config) {
        this.config = new CookieConfig(config);
        this.tracking = new Tracking(this.config);
        this.on = emitter.on.bind(emitter);
        this.emit = emitter.emit.bind(emitter);
        // Array of callbacks to be executed when consent changes
        this.consentListeners = [];
        this.consentListenerNames = [];
        setTimeout( () => this._initialize(), 100);
    }

    _initialize() {
        switch (this._checkStatus()) {
            case statusUndefined:
                if (!this.config.enabled()) {
                    if(!this.config.isDfB()) {
                        // emit allowed usage of always tagged tracking
                        this.emit("iwCookieAllowed", ['always']);
                        this.tracking.insertTracking(['always']);
                        this.consentListeners.forEach((callback) => {
                            callback(['always']);
                        });
                    }
                } else {
                    const self = this;
                    if(!this.view){
                        this.view = new CookieView(this.config);
                    }
                    this.view.showDialog(function (resultButton, resultCheckboxes) {
                        self._buttonClickCallback(resultButton, resultCheckboxes);
                    });
                    this.emit( "iwCookieOpened" );
                    this._tCUA();
                }
                break;
            case statusSave:
                this._tCAA(this._getTypes());
                break;
            case statusAllow:
                this._tCAA(true);
                break;
            case statusDeny:
                this._tCDA();
                break;
        }
    }

    reset() {
        const cookieName = this.config.gCN();
        sC(cookieName, statusUndefined, this.config.gCED(), this.config.gCD(), this.config.gCP(), this.config.gCS());
        // delete saved types
        const cookieNameSaved = this.config.gCNS();
        dC(cookieNameSaved, statusUndefined, this.config.gCED(), this.config.gCD(), this.config.gCP(), this.config.gCS());

        this.emit( "iwCookieRevoke" );
        this.consentListeners.forEach((callback) => {
            callback(['always']);
        });

        this.tracking.removeTrackingCookies();
    }

    cookiesAllowed(){
        // disabled
        if (!this.config.enabled()) {
            return true;
        }
        // check allowed or saved
        return this._checkStatus() === statusAllow || this._checkStatus() === statusSave;
    }

    typeAllowed(type){
        // disabled
        if (!this.config.enabled()) {
            return false;
        }
        // no type given
        if(!type){
            return false;
        }
        // all allowed
        if(this._checkStatus() === statusAllow){
            return true;
        }
        // search for typeIds
        const typeData = this._getTypes();
        if (!!typeData){
            return typeData.includes(type);
        }
        return false;
    }

    _buttonClickCallback(resultButton, resultCheckboxes) {
        if (resultButton === statusAllow) {
            this._setStatus(statusAllow);
            this._tCAA(true);
        } else if (resultButton === statusSave) {
            this._setStatus(statusSave);
            this._setTypes(resultCheckboxes);
            this._tCAA(resultCheckboxes);
        } else {
            this._setStatus(statusDeny);
            this._tCDA();
        }
    }

    _checkStatus() {
        const cookieName = this.config.gCN();
        const answer = gC(cookieName);
        if (answer) {
            if (!statuses.includes(answer)) {
                return statusUndefined;
            }
            return answer;
        } else {
            return statusUndefined;
        }
    }

    _getTypes() {
        const cookieNameTypes = this.config.gCNS();
        const answer = gC(cookieNameTypes);
        if (answer) {
            return answer.split(',');
        } else {
            return false;
        }
    }

    _setTypes(value) {
        const cookieNameTypes = this.config.gCNS();
        sC(cookieNameTypes, value.join(','), this.config.gCED(), this.config.gCD(), this.config.gCP(), this.config.gCS());
        return true;
    }

    _setStatus(value) {
        if (!statuses.includes(value)) {
            return false;
        }
        const cookieName = this.config.gCN();
        sC(cookieName, value, this.config.gCED(), this.config.gCD(), this.config.gCP(), this.config.gCS());
        return true;
    }

    _tCAA(allowedTypeIds) {
        const allowedTypeIdsModified = allowedTypeIds === true ? this.config.gCTypeIds('all') : allowedTypeIds
        this.emit("iwCookieAllowed", allowedTypeIdsModified);
        this.tracking.insertTracking(allowedTypeIdsModified);
        this.consentListeners.forEach((callback) => {
            callback(allowedTypeIdsModified);
        });
    }

    _tCDA() {
        this.emit("iwCookieDenied");
        this.tracking.removeTrackingCookies();
        this.tracking.insertTracking(['always']);
        this.consentListeners.forEach((callback) => {
            callback(['always']);
        });
    }

    _tCUA() {
        this.tracking.removeTrackingCookies();
        if(this.config.gPos() !== positionOverlay){
            // if style is not overlay user can continue to use site so we enable anonymous tracking
            this.tracking.insertTracking(['always']);
            this.consentListeners.forEach((callback) => {
                callback(['always']);
            });
        }
    }
}
