import defaultCookieConfig from './default';
import {hash, positionBanner, positionBannerBottom, positionBannerTop, positionOverlay} from '../util/constants';


export default class CookieConfig {

    constructor(config) {
        this._defaultConfig = defaultCookieConfig;
        if (config) {
            this._config = config;
        } else {
            this._config = null;
        }
    }

    enabled() {
        if(this.isDfB()){
            return false;
        }
        return this.gP(['enabled']);
    }

    isDfB(){
        if(this.gP(['disable_for_bots'])){
            if(navigator){
                if((/bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex|Chrome-Lighthouse/i.test(navigator.userAgent))){
                    return true;
                }
            }
        }
        return false;
    }

    getContainer() {
        return this.gP(['container']);
    }

    gCTypeIds(defaultTypeId) {
        let ids = [];
        if(!!defaultTypeId){
            ids.push(defaultTypeId);
        }
        const types = this.gP(['types']);
        if(!!types){
            for (let type of types) {
                if(type['id']){
                    ids.push(type['id']);
                }
            }
        }
        return ids;
    }

    gMBP(){
        return this.gP(['modify_body_padding']);
    }

    gMBPC(){
        return this.gP(['modify_body_padding_container']);
    }

    gMBPI(){
        return this.gP(['modify_body_padding_inverse']);
    }

    gCN() {
        return this.gP(['cookie', 'name']);
    }

    gCNS() {
        return this.gP(['cookie', 'nameSaved']);
    }

    gCP() {
        return this.gP(['cookie', 'path']);
    }

    gCD() {
        return this.gP(['cookie', 'domain']);
    }

    gCED() {
        return this.gP(['cookie', 'expiryDays']);
    }

    gCS() {
        return this.gP(['cookie', 'secure']);
    }

    gContL() {
        return this.gP(['content', 'links']);
    }

    gContI() {
        return this.gP(['content', 'image']);
    }

    gColPalHash() {
        const data = this.gP(['palette']);
        return hash(JSON.stringify(data));
    }

    gColPalId() {
        return 'iw-cc-color-or-' + this.gColPalHash();
    }

    _gElmHead() {
        let data = this.gP(['elements', 'header']);
        data = CookieConfig._rA(data, '{{header}}', this.gP(['content', 'header']));
        return data;
    }

    _gElmMsg() {
        let data = this.gP(['elements', 'message']);
        return CookieConfig._rA(data, '{{message}}', this.gP(['content', 'message']));
    }

    _gElmQ() {
        let data = this.gP(['elements', 'question']);
        return CookieConfig._rA(data, '{{question}}', this.gP(['content', 'question']));
    }

    _gElmA(pos) {
        let data = this.gP(['elements', 'allow']);
        data = CookieConfig._rA(data, '{{allow}}', this.gP(['content', 'allow']));
        return CookieConfig._rA(data, '{{pos}}', pos);
    }

    _gElmS(pos) {
        let data = this.gP(['elements', 'save']);
        data = CookieConfig._rA(data, '{{save}}', this.gP(['content', 'save']));
        return CookieConfig._rA(data, '{{pos}}', pos);
    }

    _gElmD(pos) {
        let data = this.gP(['elements', 'deny']);
        data = CookieConfig._rA(data, '{{deny}}', this.gP(['content', 'deny']));
        return CookieConfig._rA(data, '{{pos}}', pos);
    }

    _gElmL(link) {
        if (!link['title'] || !link['url'] || link['title'] === '') {
            return '';
        }
        let data = this.gP(['elements', 'link']);
        data = CookieConfig._rA(data, '{{title}}', link['title']);
        data = CookieConfig._rA(data, '{{url}}', link['url']);
        data = CookieConfig._rA(data, '{{target}}', this.gP(['content', 'target']));
        return data;
    }

    _gElmBtns() {
        let data = this.gP(['elements', 'buttons']);
        const types = this.gP(['types']);
        if(!!types){
            // types defined so we show checkboxes + save button
            data = CookieConfig._rA(data, '{{allow}}', this._gElmS('iw-cc-btn-left'));
            data = CookieConfig._rA(data, '{{deny}}', this._gElmA('iw-cc-btn-right'));
        }else{
            // types not set so we show deny button
            data = CookieConfig._rA(data, '{{allow}}', this._gElmA('iw-cc-btn-left'));
            data = CookieConfig._rA(data, '{{deny}}', this._gElmD('iw-cc-btn-right'));
        }
        return data;
    }

    _gElmCBox(type) {
        let data = this.gP(['elements', 'checkbox']);
        data = CookieConfig._rA(data, '{{id}}', 'iw-cc-checkbox-' + type['id']);
        data = CookieConfig._rA(data, '{{force}}', !!type['force'] ? 'force' : '');
        data = CookieConfig._rA(data, '{{checked}}', !!type['force'] ? 'checked disabled' : '');
        return CookieConfig._rA(data, '{{name}}', type['name']);
    }

    _gElmCBoxes() {
        const types = this.gP(['types']);
        if(!types){
           return '';
        }
        const data = this.gP(['elements', 'checkboxes']);
        let boxesHtml = [];
        for (let type of types) {
            boxesHtml.push(this._gElmCBox(type));
        }
        return CookieConfig._rA(data, '{{checkboxes}}', boxesHtml.join(''));
    }

    _gElmLs() {
        let linksRaw = this.gContL();
        let linksHtml = [];
        for (let link of linksRaw) {
            linksHtml.push(this._gElmL(link));
        }
        let data = this.gP(['elements', 'links']);
        data = CookieConfig._rA(data, '{{links}}', linksHtml.join(''));
        return data;
    }

    _gElmImg() {
        const contentImage = this.gContI();
        if (!contentImage['title'] || !contentImage['url'] || contentImage['title'] === '') {
            return '';
        }
        let data = this.gP(['elements', 'image']);
        data = CookieConfig._rA(data, '{{title}}', contentImage['title']);
        data = CookieConfig._rA(data, '{{url}}', contentImage['url']);
        return data;
    }

    gPos() {
        const position = this.gP(['position']);
        if(position === positionOverlay && window && window.location && window.location.href){
            let linksRaw = this.gContL();
            if(linksRaw){
                const locationString = String(window.location.href).toLowerCase().replace(/https/i, "").replace(/http/i, "");
                const locationPath = String(window.location.pathname).toLowerCase();
                for (let link of linksRaw) {
                    if(link['url']){
                        const linkString = String(link['url']).toLowerCase().replace(/https/i, "").replace(/http/i, "");
                        if(linkString === locationString){
                            return this.gP(['fallback_position']);
                        }
                        if(linkString === locationPath){
                            return this.gP(['fallback_position']);
                        }
                    }
                }
            }
        }
        // switch to fallback for devices with low height
        if(this.gP(['disable_overlay_for_low_height'])){
            const h = window.innerHeight;
            if(h != null){
                if(h < 500){
                    return this.gP(['fallback_position']);
                }
            }
        }

        return position;
    }

    gPosCls() {
        return this.gPos().split('-').map(pos => 'iw-cc-' + pos);
    }

    gPosStyl() {
        return this.gPos() === positionBannerTop || this.gPos() === positionBannerBottom
            ? positionBanner
            : positionOverlay;
    }

    getWindow() {

        let positionStyle = this.gPosStyl();

        let childViews = [];
        if(positionStyle !== positionBanner && this.gContI()){
            childViews.push(this._gElmImg());
        }

        if (positionStyle === positionBanner) {
            childViews.push('<div class="iw-cc-banner-left-col">');
        }

        childViews.push(this._gElmHead());
        childViews.push(this._gElmMsg());

        if (positionStyle === positionBanner) {
            childViews.push('</div><div class="iw-cc-banner-right-col">');
        }

        childViews.push(this._gElmQ());
        childViews.push(this._gElmCBoxes());
        childViews.push(this._gElmBtns());

        if (positionStyle === positionBanner) {
            childViews.push('</div><div class="iw-cc-clearfix"></div>');
        }

        childViews.push(this._gElmLs());

        let wrapper = this.gP(['elements', 'wrapper']);
        wrapper = CookieConfig._rA(wrapper, '{{children}}', childViews.join(''));

        const classes = [
            'iw-cc-' + positionStyle,
        ];

        if (positionStyle === positionBanner) {
            classes.push.apply(classes, this.gPosCls());
        }

        classes.push(this.gColPalId());

        let mainViews = [];
        if (positionStyle === positionOverlay) {
            mainViews.push(this.gP(['elements', 'overlay']));
        }
        mainViews.push(wrapper);

        let data = this.gP(['elements', 'window']);
        data = CookieConfig._rA(data, '{{children}}', mainViews.join(''));
        data = CookieConfig._rA(data, '{{classes}}', classes.join(' '));
        return data;
    }

    static _rA(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    gP(propertySelector) {
        let data = CookieConfig._gPR(this._config, propertySelector);
        if (data === null || data === undefined) {
            data = CookieConfig._gPR(this._defaultConfig, propertySelector);
        }
        return data;
    }

    static _gPR(data, propertySelector) {
        if (!propertySelector || !propertySelector instanceof Array || propertySelector.length === 0) {
            return data;
        }
        if (data != null && propertySelector.length > 0) {
            if (data[propertySelector[0]] != null) {
                return CookieConfig._gPR(data[propertySelector[0]], propertySelector.slice(1));
            }
        }
        return null;
    }
}
